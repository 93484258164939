var eventOrder = function () {
    var eventOrder = this;

    this.eventId = undefined;

    this.init = function () {
        eventOrder.eventId = $("#event-id").val();

        $('#placement-table').find('input.chk').unbind('click').bind('click', function () {
            var eventDetailId = $(this).attr('data-event-detail-id');

            eventOrder.updateTotalEventDetail(eventDetailId);
        });

        $('#placement-table').find('input.chkAll').unbind('click').bind('click', function () {
            var $tr = $(this).closest('tr');

            if ($(this).is(':checked')) {
                // devo selezionare tutto
                $tr.find(".chk").prop('checked', true);
            } else {
                // devo deselezionare tutto
                $tr.find(".chk").prop('checked', false);
            }

            eventOrder.updateTotalEventDetail();
        });

        $('[data-interaction=reset]').unbind('click').bind('click', function () {
            if (confirm($(this).attr('data-confirm'))) {
                var eventDetailId = $(this).attr('data-event-detail-id');

                $('#placement-table').find('input.chk[data-event-detail-id=' + eventDetailId + ']').prop("checked", false);
                $('#placement-table').find('input.chkAll').prop("checked", false);

                eventOrder.updateTotalEventDetail(eventDetailId);
            }
        });

        $('[data-interaction=confirm]').unbind('click').bind('click', function () {
            eventOrder.confirmOrders()
                .done(function (data) {
                    if (data.response) {
                        app.success(data.message);
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });

        $('[data-interaction=review]').unbind('click').bind('click', function () {
            var url = $(this).attr('data-url');

            eventOrder.confirmOrders()
                .done(function (data) {
                    if (data.response) {
                        app.success(data.message);
                        app.href(url);
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });

        if ($("#details").length > 0) {
            $.get(app.baseUrl + "/event/" + eventOrder.eventId + "/event_detail?review=1")
                .done(function (data) {
                    $("#details").html(data);

                    var dt = $("#details").find("#tblEventDetail_default_" + eventOrder.eventId).data('dataTable');

                    dt.afterSuccessEditable = function () {
                        app.reload();
                    }
                });
        }

        $('[data-interaction=download]').unbind('click').bind('click', function () {
            var type = $(this).attr('data-type');
            var format_file = $(this).attr('data-format');

            app.block(1);
            $.post(app.baseUrl + "/event/" + eventOrder.eventId + "/generate/" + type, {format_file:format_file})
                .done(function (data) {
                    if (data.response) {
                        window.location.href = data.message;
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });

        $('[data-interaction=download_labels]').unbind('click').bind('click', function () {
            var type = $(this).attr('data-type');
            var format_file = $(this).attr('data-format');

            app.block(1);
            $.post(app.baseUrl + "/event/" + eventOrder.eventId + "/generate/" + type, {format_file:format_file})
                .done(function (data) {
                    if (data.response) {
                        app.success(data.message);
                        if ($("#containerPrint").hasClass("col-xs-12"))
                            $("#containerPrint").removeClass("col-xs-12").addClass("col-xs-6");
                        $("#containerDownload").css("display", "");

                        var interval = setInterval(function() {
                            $.get(app.baseUrl + "/event/finish_generate_label")
                                .done(function (data) {
                                    if (data.response && (data.message != "")) {
                                        if ($("#containerPrint").hasClass("col-xs-6"))
                                            $("#containerPrint").removeClass("col-xs-6").addClass("col-xs-12");
                                        $("#containerDownload").css("display", "none");
                                        window.location.href = data.message;
                                        clearInterval(interval);
                                    }
                                })
                                .fail(function (data) {
                                    app.error("", app.parseAjaxError(data));
                                });
                        }, 5000);
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });


        });

        $('[data-interaction=import]').dropzone({
            url: app.baseUrl,
            headers: $.ajaxSetup().headers,
            previewTemplate: '<div style="display: none;"></div>',
            acceptedFiles: ".xls,.xlsx,.csv",
            init: function() {
                var eventDetailId = $(this.element).attr('data-event-detail-id');
                this.on("processing", function(file) {
                    this.options.url = app.baseUrl + "/event/" + eventOrder.eventId + "/order/parse/" + eventDetailId;
                    file.eventDetailId = eventDetailId;
                });
            },
            sending: function (file, data, formData) {
                app.block(1);
            },
            success: function (file, data) {
                app.block(0);
                $('.dz-preview').detach();

                $.each(data.message.success, function () {
                    $(".chk[data-shop-id=" + this + "][data-event-detail-id=" + file.eventDetailId + "]").prop("checked", true);
                });

                var message = data.message;
                eventOrder.confirmOrders(1)
                    .done(function (data) {
                        if (data.response) {
                            app.success(data.message);

                            eventOrder.updateTotalEventDetail(file.eventDetailId);

                            if (message.error.length == 0) {
                                app.success("Importazione avvenuta con successo");
                            } else {
                                $("#alert").html('Importazione non avvenuta per i seguenti negozi: ' + message.error.join(", "));
                                $("#alert").closest('.alert').show();
                            }
                        } else {
                            app.warning("", data.message);
                        }

                        app.block(0);
                    })
                    .fail(function (data) {
                        app.block(0);
                        app.error("", app.parseAjaxError(data));
                    });
            },
            error: function (file, data) {
                app.block(0);
                console.log("error");
            }
        });

        $('[data-interaction=notes]').unbind('click').bind('click', function () {
            var notes = $("#notes").val();

            app.block(1);
            $.put(app.baseUrl + "/event/" + eventOrder.eventId, {op: "inline", notes: notes})
                .done(function (data) {
                    if (data.response) {
                        app.reload();
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });

        eventOrder.updateTotalEventDetail();
    };

    this.confirmOrders = function (block) {
        var orders = {};
        block = block || 1;

        $('#placement-table').find('.order-detail').each(function () {
            $(this).find('.chk').each(function () {
                if ($(this).is(':checked')) {
                    var shopId = parseInt($(this).attr('data-shop-id'));
                    var eventDetailId = parseInt($(this).attr('data-event-detail-id'));

                    if (typeof orders[shopId] === 'undefined')
                        orders[shopId] = [];

                    orders[shopId].push(eventDetailId);
                }
            });
        });

        app.block(block);
        return $.post(app.baseUrl + "/event/" + eventOrder.eventId + "/order/placement", {orders: orders});
    };

    this.updateTotalEventDetail = function (eventDetailId) {
        if (typeof eventDetailId !== 'undefined') {
            // devo aggiornare il totale solo per questo dettaglio
            var count = $('[data-event-detail-id=' + eventDetailId + ']:checked').length;

            $('[data-total-event-detail-id=' + eventDetailId + ']').html(count);
        } else {
            // devo aggiornare i totali per tutti i dettagli
            $('[data-total-event-detail-id]').each(function () {
                var eventDetailId = $(this).attr('data-total-event-detail-id');

                eventOrder.updateTotalEventDetail(eventDetailId);
            });
        }
    };
};
